import i18n from '@/plugins/i18n';

const { n } = i18n.global;

const bingPRXImageConditionsCodebook = [
    {
        required: true,
        aspectRatio: {
            value: 1,
            localizedLabel: '1:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 470,
                    label: '470px',
                },
                height: {
                    value: 470,
                    label: '470px',
                },
            },
            maximum: {
                width: {
                    value: 2048,
                    label: '2048px',
                },
                height: {
                    value: 2048,
                    label: '2048px',
                },
            },
        },
    },
    {
        required: true,
        aspectRatio: {
            value: 1.91,
            label: '1.91:1',
            localizedLabel: n(1.91) + ':1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 703,
                    label: '703px',
                },
                height: {
                    value: 368,
                    label: '368px',
                },
            },
            maximum: {
                width: {
                    value: 2592,
                    label: '2592px',
                },
                height: {
                    value: 1357,
                    label: '1357px',
                },
            },
        },
    },
    {
        required: false,
        aspectRatio: {
            value: 4,
            localizedLabel: '4:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 608,
                    label: '608px',
                },
                height: {
                    value: 152,
                    label: '152px',
                },
            },
            maximum: {
                width: {
                    value: 2592,
                    label: '2592px',
                },
                height: {
                    value: 648,
                    label: '648px',
                },
            },
        },
    },
    {
        required: false,
        aspectRatio: {
            value: 0.5,
            localizedLabel: '1:2',
        },
        sizes: {
            minimum: {
                width: {
                    value: 470,
                    label: '470px',
                },
                height: {
                    value: 940,
                    label: '940px',
                },
            },
            maximum: {
                width: {
                    value: 1024,
                    label: '1024px',
                },
                height: {
                    value: 2048,
                    label: '2048px',
                },
            },
        },
    },
]

export default bingPRXImageConditionsCodebook;
