import {greatestCommonDivisor} from "@/utils/greatestCommonDivisor";

export const getImageDimensionsAndRatio = async (
    imgBlob: string
): Promise<{
    aspectRatio: number;
    width: number;
    height: number;
} | void> => {
    if (!imgBlob) {
        return;
    }

    return new Promise<any>((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            const commonDivisor = greatestCommonDivisor(img.width, img.height);
            const aspectRatio = img.width / commonDivisor / (img.height / commonDivisor);

            resolve({
                aspectRatio: Number.isInteger(aspectRatio) ? aspectRatio : Number(aspectRatio.toFixed(2)),
                width: img.width,
                height: img.height,
            });
        };

        img.src = imgBlob;

        img.onerror = function () {
            reject(new Error('Error loading image'));
        };
    });
};
