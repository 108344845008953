<template>
  <div class="div-primary mx-auto mt-4">
    <div class="flex items-baseline">
      <h1 class="mb-12 text-xl font-bold text-main md:text-3xl 2xl:text-4xl">{{ $t('dashboard.conditionMeasurement.header') }}</h1>
      <h2 class="ml-3 text-center text-main sm:text-left" v-html="$t('dashboard.conditionMeasurement.subHeader')"></h2>
    </div>
    <div class="mx-auto w-fit items-baseline justify-between gap-x-8 gap-y-10 pb-10 md:flex md:w-full md:flex-wrap">
      <div v-for="(measurement, index) in measurements" class="mt-8 md:mt-0" :key="index">
        <div class="ml-0 mr-auto flex items-center">
          <div :class="[measurement.isOk ? 'bg-green-500 shadow-full shadow-green-500' : ' bg-red-600 shadow-full shadow-red-600', 'mr-2 block h-5 w-5 rounded-full']"></div>
          <p class="text-main">{{ getLabelByType(measurement.type) }}</p>
        </div>
        <div v-if="!measurement.isOk" class="mt-3 flex items-center" @click="openDialogByType(measurement.type)">
          <ExclamationTriangleIcon class="mr-1 h-5 w-5 text-yellow-500"></ExclamationTriangleIcon>
          <p class="text-sm text-gray-500 hover:cursor-pointer hover:underline">{{ $t('dashboard.conditionMeasurement.howToFix') }}</p>
        </div>
      </div>
    </div>
    <div v-if="hasOrders" class="border-t pt-10">
      <div class="mb-12 flex items-center">
        <h1 class="text-xl font-bold text-main md:text-3xl 2xl:text-4xl">{{ $t('dashboard.conditionMeasurement.userMeasurementTitle') }}</h1>
        <span v-if="userMeasurement" :class="[userMeasurement?.value * 100 < 90 ? 'text-red-700' : 'text-lime-500', 'ml-3 text-xl font-bold text-main md:text-3xl 2xl:text-4xl']"><LocalizedValue :value="userMeasurement"></LocalizedValue></span>
      </div>
      <p class="text-left text-main">{{ $t('dashboard.conditionMeasurement.userMeasurementText1') }}</p>
      <div class="flex items-center">
        <p class="text-left text-main">{{ $t('dashboard.conditionMeasurement.userMeasurementText2') }}</p>
        <p class="ml-1 text-left font-bold text-main">{{ $t('dashboard.conditionMeasurement.userMeasurementText3', { userNonMeasurement }) }}</p>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="visibleUserInformation" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.usersInformation1') }}</p>
      <p class="mt-3 text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.usersInformation2') }}</p>
      <p class="mt-1 text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.usersInformation3') }}</p>
      <p class="mt-1 text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.usersInformation4') }}</p>
    </div>
    <template #footer> </template>
  </Dialog>
  <Dialog v-model:visible="visibleCartAdditionsInformation" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.cartAdditionsInformation1') }}</p>
      <p class="mt-3 text-left text-base font-bold text-gray-500">
        <span>{{ $t('dashboard.conditionMeasurement.moreInformationGoogle') }}</span
        ><a href="https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag" target="_blank" class="text-main hover:cursor-pointer hover:underline">{{ $t('dashboard.conditionMeasurement.here') }}</a>
      </p>
    </div>
    <template #footer> </template>
  </Dialog>
  <Dialog v-model:visible="visibleOrdersInformation" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.ordersInformation1') }}</p>
      <p class="mt-3 text-left text-base font-bold text-gray-500">
        <span>{{ $t('dashboard.conditionMeasurement.moreInformationGoogle') }}</span
        ><a href="https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag" target="_blank" class="text-main hover:cursor-pointer hover:underline">{{ $t('dashboard.conditionMeasurement.here') }}</a>
      </p>
    </div>
    <template #footer> </template>
  </Dialog>
  <Dialog v-model:visible="visibleSoldProductsInformation" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dashboard.conditionMeasurement.soldProductsInformation1') }}</p>
      <p class="mt-3 text-left text-base font-bold text-gray-500">
        <span>{{ $t('dashboard.conditionMeasurement.moreInformationGoogle') }}</span
        ><a href="https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag" target="_blank" class="text-main hover:cursor-pointer hover:underline">{{ $t('dashboard.conditionMeasurement.here') }}</a>
      </p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';

const { t } = useI18n();
const toast = useToast();

const eshopsStore = useEshopsStore();

const visibleUserInformation = ref(false);
const visibleCartAdditionsInformation = ref(false);
const visibleOrdersInformation = ref(false);
const visibleSoldProductsInformation = ref(false);

const measurements = ref();

const userMeasurement = ref();

const userNonMeasurement = ref();

const hasOrders = ref();

onMounted(async () => {
  await getConditionMeasurement();
  await getMeasuredCustomersPercentage();
});

const getConditionMeasurement = async () => {
  try {
    const response = await api.clientDashboardGetPixelMeasuringCondition();
    measurements.value = Object.keys(response.data).map((key) => {
      return {
        type: key,
        isOk: response.data[key],
      };
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getMeasuredCustomersPercentage = async () => {
  try {
    const response = await api.clientDashboardGetSuccessfullyMeasuredCustomersPercentage();

    userMeasurement.value = response.data.successfullyMeasuredCustomersPercentage;

    const measuredValue = Number(response.data.successfullyMeasuredCustomersPercentage?.value);
    if (isNaN(measuredValue)) {
      hasOrders.value = false;
    } else {
      userNonMeasurement.value = 100 - measuredValue * 100;
      userNonMeasurement.value = userNonMeasurement.value.toFixed(2);
    }

    hasOrders.value = response.data.hasOrders;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getConditionMeasurement();
    await getMeasuredCustomersPercentage();
  }
);

const openDialogByType = (type: string) => {
  switch (type) {
    case 'users':
      visibleUserInformation.value = true;
      break;
    case 'addToCart':
      visibleCartAdditionsInformation.value = true;
      break;
    case 'purchase':
      visibleOrdersInformation.value = true;
      break;
    case 'products':
      visibleSoldProductsInformation.value = true;
      break;
    default:
      return '';
  }
};

const getLabelByType = (type: string) => {
  switch (type) {
    case 'users':
      return t('dashboard.conditionMeasurement.users');
    case 'addToCart':
      return t('dashboard.conditionMeasurement.cartAdditions');
    case 'purchase':
      return t('dashboard.conditionMeasurement.orders');
    case 'products':
      return t('dashboard.conditionMeasurement.soldProducts');
    default:
      return '';
  }
};
</script>
