const bingPRXLogoConditionsCodebook = [
    {
        required: true,
        aspectRatio: {
            value: 1,
            localizedLabel: '1:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 128,
                    label: '128px',
                },
                height: {
                    value: 128,
                    label: '128px',
                },
            },
            maximum: {
                width: {
                    value: 2048,
                    label: '2048px',
                },
                height: {
                    value: 2048,
                    label: '2048px',
                },
            },
        },
    },
    {
        required: false,
        aspectRatio: {
            value: 4,
            localizedLabel: '4:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 512,
                    label: '512px',
                },
                height: {
                    value: 128,
                    label: '128px',
                },
            },
            maximum: {
                width: {
                    value: 2592,
                    label: '2592px',
                },
                height: {
                    value: 648,
                    label: '648px',
                },
            },
        },
    },
]

export default bingPRXLogoConditionsCodebook;
