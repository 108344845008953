<template>
  <section>
    <CampaignFormat
      v-if="showFormats && !loading && router.currentRoute.value.name !== CAMPAIGNS_ROUTES.EDIT_STEP"
      :formats="formats"
      :campaign="props.campaign"
      @changeCampaignFormatStatus="onChangeCampaignFormatStatus"
      @updateFormats="onUpdateFormats"
    />
    <AssetsSkeleton v-if="loading" />
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense timeout="0">
          <template #default>
            <component :is="Component"></component>
          </template>
          <template #fallback>
          </template>
        </Suspense>
      </template>
    </RouterView>
    <Dialog v-model:visible="openMicrosoftStoreStatusModal" :draggable="false" :closable="true" modal :header="$t('campaigns.microsoft.microsoftStoreStatusHeader')" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col items-center md:flex-row">
        <div>
          <ExclamationTriangleIcon class="h-8 w-8 text-yellow-500" aria-hidden="true" />
        </div>
        <p class="ml-4 text-main">
          {{ $t('campaigns.microsoft.microsoftStoreStatusText') }}
        </p>
      </div>
      <template #footer> </template>
    </Dialog>
  </section>
</template>
<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import {PropType, provide, ref, watch} from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteUpdate, RouterView, useRouter } from 'vue-router';
import AssetsSkeleton from '@/modules/campaigns/components/AssetsSkeleton.vue';
import CampaignFormat from '@/modules/campaigns/components/CampaignFormat.vue';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import { useFacebookDPAWizardStore } from '@/modules/campaigns/stores/facebook/DPA/wizard';
import { useGoogleDSAWizardStore } from '@/modules/campaigns/stores/google/DSA/wizard';
import { useGooglePRXWizardStore } from '@/modules/campaigns/stores/google/PRX/wizard';
import { useMicrosoftBING_DSAWizardStore } from '@/modules/campaigns/stores/microsoft/BING_DSA/wizard';
import { useMicrosoftBING_PRXWizardStore } from '@/modules/campaigns/stores/microsoft/BING_PRX/wizard';
import { translateCampaignStatusFromEnumToBoolean } from '@/modules/campaigns/utils/translateCampaignStatusFromEnumToBoolean';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsCampaignApiStatus, EnumsMarketingChannel, EnumsMarketingFormat, EnumsMicrosoftStoreStatus } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<EnumsMarketingChannel>,
    required: false,
    default: EnumsMarketingChannel.Google,
  },
});

const loading = ref(false);

const toast = useToast();
const { t } = useI18n();
const router = useRouter();

const eshopStore = useEshopsStore();
const prxWizardStore = useGooglePRXWizardStore();
const dsaWizardStore = useGoogleDSAWizardStore();
const metaDpaWizardStore = useFacebookDPAWizardStore();
const bingDsaWizardStore = useMicrosoftBING_DSAWizardStore();
const bingPrxWizardStore = useMicrosoftBING_PRXWizardStore();

const googleFormats = [
  {
    type: EnumsMarketingFormat.Pla,
    name: t('campaigns.googleShopping'),
    imageUrl: '/campaignFormats/pla.png',
  },
  {
    type: EnumsMarketingFormat.Prx,
    name: t('campaigns.performanceMax'),
    imageUrl: '/campaignFormats/prx.png',
    stateStore: prxWizardStore,
  },
  {
    type: EnumsMarketingFormat.Dsa,
    name: t('campaigns.google.dsa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
    stateStore: dsaWizardStore,
  },
];

const metaFormats = [
  {
    type: EnumsMarketingFormat.Dpa,
    name: t('campaigns.meta.dpa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
    stateStore: metaDpaWizardStore,
  },
];

const microsoftFormats = [
  {
    type: EnumsMarketingFormat.BingPla,
    name: t('campaigns.microsftShopping'),
    imageUrl: '/campaignFormats/bing_pla.svg',
  },
  {
    type: EnumsMarketingFormat.BingPrx,
    name: t('campaigns.performanceMax'),
    imageUrl: '/campaignFormats/bing_prx.svg',
    stateStore: bingPrxWizardStore,
  },
  {
    type: EnumsMarketingFormat.BingDsa,
    name: t('campaigns.microsoft.dsa.editTitle'),
    imageUrl: '/campaignFormats/bing_dsa.svg',
    stateStore: bingDsaWizardStore,
  },
];

const formats = ref();

const initData = async () => {
  loading.value = true;
  await setFormats();
  await loadFormatsInfo();
};

const setFormats = async () => {
  switch (props.campaign) {
    case EnumsMarketingChannel.Google:
      formats.value = googleFormats;
      break;
    case EnumsMarketingChannel.Facebook:
      formats.value = metaFormats;
      break;
    case EnumsMarketingChannel.Microsoft:
      formats.value = microsoftFormats;
      break;
    default:
      formats.value = [];
  }
};

const getFormatStatus = async (format) => {
  try {
    const { data: campaignStatus } = await api.clientCampaignsGetStatus(format);
    return { status: translateCampaignStatusFromEnumToBoolean(campaignStatus.desiredStatus), problem: campaignStatus.problemDetail };
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError'),  life: 6000 });
    return { status: false, problem: 'error' };
  }
};

const getDailyBudget = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetDailyBudget(format);
    return data;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError'),  life: 6000 });
    return null;
  }
};

const getCampaignExist = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetCampaignStatus(format);
    return data.campaignExists;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError'),  life: 6000});
    return false;
  }
};

// Microsoft extra

const openMicrosoftStoreStatusModal = ref(false);

const showMicrosoftStoreStatusModal = async () => {
  // Show Microsoft store status modal on formats page
  if (router.currentRoute.value.name === CAMPAIGNS_ROUTES.FORMATS) {
    const isAnyFormatActive = formats.value.some((format) => format.stateStore && format.stateStore.formatStatus === true);

    if (isAnyFormatActive) {
      try {
        const { data } = await api.clientCampaignsGetMicrosoftStoreStatus();
        openMicrosoftStoreStatusModal.value = data.status === EnumsMicrosoftStoreStatus.Waiting;
      } catch (error) {
        toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError'), life: 6000 });
      }
    }
  }
};

const getMicrosoftApiStatus = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetApiStatus(format);
    return data.status;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError'), life: 6000 });
    return EnumsCampaignApiStatus.NotPlanned;
  }
};

const loadFormatsInfo = async () => {
  try {
    const formatStatuses = await Promise.all(formats.value.map((format) => getFormatStatus(format.type)));
    const dailyBudgets = await Promise.all(formats.value.map((format) => getDailyBudget(format.type)));
    const campaignExists = await Promise.all(formats.value.map((format) => getCampaignExist(format.type)));

    formats.value.forEach((format, index) => {
      format.formatStatus = formatStatuses[index].status;
      format.problemDetail = formatStatuses[index].problem;
      format.budget = dailyBudgets[index];
      format.isCampaignExist = format.type === EnumsMarketingFormat.Pla ? true : campaignExists[index];

      let campaignFormats;

      switch (props.campaign) {
        case EnumsMarketingChannel.Google:
          campaignFormats = googleFormats;
          break;
        case EnumsMarketingChannel.Facebook:
          campaignFormats = metaFormats;
          break;
        case EnumsMarketingChannel.Microsoft:
          campaignFormats = microsoftFormats;
          break;
        default:
          break;
      }

      // For global state
      if (campaignFormats[index] && campaignFormats[index].stateStore) {
        campaignFormats[index].stateStore.formatStatus = formatStatuses[index].status;
        campaignFormats[index].stateStore.isEdit = format.isCampaignExist;
      }
    });

    // Microsoft extra
    if (props.campaign === EnumsMarketingChannel.Microsoft) {
      // Get Microsoft API status for each format with existing campaign
      const microsoftApiStatuses = await Promise.all(formats.value.filter((format) => format.isCampaignExist).map((format) => getMicrosoftApiStatus(format.type)));

      formats.value.forEach((format, index) => {
        if (format.isCampaignExist) {
          format.apiStatus = microsoftApiStatuses[index];
        }
      });

      await showMicrosoftStoreStatusModal();
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

await initData();

const onChangeCampaignFormatStatus = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index].formatStatus = format.formatStatus;
  formats.value[index].problemDetail = format.problemDetail;
  formats.value[index].isCampaignExist = format.isCampaignExist;

  let campaignFormats;

  switch (props.campaign) {
    case EnumsMarketingChannel.Google:
      campaignFormats = googleFormats;
      break;
    case EnumsMarketingChannel.Facebook:
      campaignFormats = metaFormats;
      break;
    case EnumsMarketingChannel.Microsoft:
      campaignFormats = microsoftFormats;
      break;
    default:
      break;
  }

  if (campaignFormats[index] && campaignFormats[index].stateStore) {
    campaignFormats[index].stateStore.formatStatus = format.formatStatus;
    campaignFormats[index].stateStore.isEdit = format.isCampaignExist;
  }

  // Microsoft extra
  if (props.campaign === EnumsMarketingChannel.Microsoft) {
    // Get Microsoft API status for format with existing campaign
   if (format.isCampaignExist) {
      format.apiStatus = await getMicrosoftApiStatus(format.type);
   }

    await showMicrosoftStoreStatusModal();
  }
};

const onUpdateFormats = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index] = format;
};

const showFormats = ref(true);

onBeforeRouteUpdate((to) => {
  showFormats.value = !(to.name === CAMPAIGNS_ROUTES.EDIT_STEP || to.name === CAMPAIGNS_ROUTES.EDIT);
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await initData();
  }
);

watch(
  () => props.campaign,
  async () => {
    await initData();
  }
);

provide('onChangeCampaignFormatStatus', onChangeCampaignFormatStatus);
</script>
