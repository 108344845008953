const googlePRXLogoConditionsCodebook = [
    {
        required: true,
        aspectRatio: {
            value: 1,
            localizedLabel: '1:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 128,
                    label: '128px',
                },
                height: {
                    value: 128,
                    label: '128px',
                },
            },
            maximum: {
                width: {
                    value: Infinity,
                    label: '∞',
                },
                height: {
                    value: Infinity,
                    label: '∞',
                },
            },
            recommended: {
                width: {
                    value: 1200,
                    label: '1200px',
                },
                height: {
                    value: 1200,
                    label: '1200px',
                },
            },
        },
    },
    {
        required: false,
        aspectRatio: {
            value: 4,
            localizedLabel: '4:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 512,
                    label: '512px',
                },
                height: {
                    value: 128,
                    label: '128px',
                },
            },
            maximum: {
                width: {
                    value: Infinity,
                    label: '∞',
                },
                height: {
                    value: Infinity,
                    label: '∞',
                },
            },
            recommended: {
                width: {
                    value: 1200,
                    label: '1200px',
                },
                height: {
                    value: 300,
                    label: '300px',
                },
            },
        },
    },
]

export default googlePRXLogoConditionsCodebook;
