import i18n from '@/plugins/i18n';

const { n } = i18n.global;

const googlePRXImageConditionsCodebook = [
    {
        required: true,
        aspectRatio: {
            value: 1,
            localizedLabel: '1:1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 300,
                    label: '300px',
                },
                height: {
                    value: 300,
                    label: '300px',
                },
            },
            maximum: {
                width: {
                    value: Infinity,
                    label: '∞',
                },
                height: {
                    value: Infinity,
                    label: '∞',
                },
            },
            recommended: {
                width: {
                    value: 1200,
                    label: '1200px',
                },
                height: {
                    value: 1200,
                    label: '1200px',
                },
            },
        },
    },
    {
        required: true,
        aspectRatio: {
            value: 1.91,
            localizedLabel: n(1.91) + ':1',
        },
        sizes: {
            minimum: {
                width: {
                    value: 600,
                    label: '600px',
                },
                height: {
                    value: 314,
                    label: '314px',
                },
            },
            maximum: {
                width: {
                    value: Infinity,
                    label: '∞',
                },
                height: {
                    value: Infinity,
                    label: '∞',
                },
            },
            recommended: {
                width: {
                    value: 1200,
                    label: '1200px',
                },
                height: {
                    value: 628,
                    label: '628px',
                },
            },
        },
    },
    {
        required: false,
        aspectRatio: {
            value: 4 / 5,
            localizedLabel: '4:5',
        },
        sizes: {
            minimum: {
                width: {
                    value: 480,
                    label: '480px',
                },
                height: {
                    value: 600,
                    label: '600px',
                },
            },
            maximum: {
                width: {
                    value: Infinity,
                    label: '∞',
                },
                height: {
                    value: Infinity,
                    label: '∞',
                },
            },
            recommended: {
                width: {
                    value: 960,
                    label: '960px',
                },
                height: {
                    value: 1200,
                    label: '1200px',
                },
            },
        },
    },
]

export default googlePRXImageConditionsCodebook;
